import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import * as styles from "../feature/whiteTightsNews/assets/css/whiteTightsNews.module.scss"

const WhiteTightsNewsPage = ({ data }) => {
  const posts = data.allMicrocmsPost.edges.map(post => ({
    title: post.node.title,
    image: post.node.image?.url,
    content: post.node.content?.trim(),
    id: post.node.id.split("-")[0],
  }))
  return (
    <Layout>
      {posts.length ? (
        <h3>白タイツくん通信</h3>
      ) : (
        <h3>・・・せっせと準備中・・・</h3>
      )}
      {!!posts.length && (
        <ul className={styles.post}>
          {posts.map((post, index) => {
            return (
              <li key={index}>
                <Link className={styles.postLink} to={`/post/${post.id}`}>
                  {post.title}
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </Layout>
  )
}

export const Head = () => <Seo title="WhiteTightsNews" />

export default WhiteTightsNewsPage

export const query = graphql`
  query {
    allMicrocmsPost {
      edges {
        node {
          id
          title
          image1 {
            url
          }
          content1
        }
      }
    }
  }
`
